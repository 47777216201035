import { Controller } from 'stimulus';
import QrScanner from 'qr-scanner';
import QrScannerWorkerPath from '!!file-loader!../../../node_modules/qr-scanner/qr-scanner-worker.min.js';
QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default class extends Controller {
  openCamera() {
    const video = document.getElementById('qr');
    this.qrScanner = new QrScanner(video, result => {
      if (result) {
        this.qrScanner.stop();
        document.getElementById('modal-qr').classList.toggle('hidden');
        document.getElementById('certificate_url').value = result;
        document.getElementById('body').classList = '';
        document.getElementById('body').style = '';
      }
    });
    this.qrScanner.start().catch(error => {
      this.qrScanner.stop();
      document.getElementById('modal-qr').classList.toggle('hidden');
      document.getElementById('body').classList = '';
      document.getElementById('body').style = '';
    });
  };

  stopCamera() {
    this.qrScanner.stop();
  }
}
