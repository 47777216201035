import { Controller } from 'stimulus';

export default class extends Controller {

  hideSecondDose() {
    document.getElementById('second-dose').classList.toggle('hidden');
    document.getElementById('second-batch').classList.toggle('hidden');
    document.getElementById('second-batch-input').value = '';
  };
}
